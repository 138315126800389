import * as React from "react"
import { Override } from "framer"

function handleClick() {
    window.history.back()
}

export const backbuttonOverride: Override = () => {
    React.useEffect(() => {
        const button = document.getElementById("backbutton")
        if (button) {
            button.addEventListener("click", handleClick)
            return () => button.removeEventListener("click", handleClick)
        }
    }, [])

    return null
}
